<template>
  <LoginContainer />
</template>

<script>
import LoginContainer from "@/containers/LoginContainer";

export default {
  name: "Login",
  components: {
    LoginContainer,
  },
};
</script>
